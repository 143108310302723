import React from "react";
import success from "../../assets/success.svg";
import { Link } from "react-router-dom";

const SuccessModal = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#1D1A27] p-6 rounded-lg w-full max-w-md mx-auto flex items-center justify-center flex-col gap-3">
        <img src={success} alt="" />
        <h2 className="text-2xl text-center font-medium mb-4 text-white">
          I post vengono generati
        </h2>

        <Link
          to={"/"}
          className="gradient_border bg-[#13111A] text-[#FFFFFF] font-medium rounded-full py-2 px-6"
        >
          Vai a Dashboard
        </Link>
      </div>
    </div>
  );
};

export default SuccessModal;
