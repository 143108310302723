import React from "react";

const GenerationBar = ({steps , step }) => {
 
  return (
    <div className="w-full flex flex-col items-center mt-8">
      <div className="flex items-center justify-center  mb-8 w-full max-w-4xl">
        {steps.map((s, index) => (
          <div key={index} className="flex items-center relative mx-auto">
            <div className="relative flex items-center justify-center w-12 h-12 rounded-full">
              <div
                style={{ border: "1px solid #181621" }}
                className={`bg-[#24212F]  flex items-center justify-center w-12 h-12 rounded-full ${
                  step >= index + 0 || (step === 4 && index === 3)
                    ? "gradient_border "
                    : ""
                }`}
              >
                <img src={s.icon} alt="icon" />
              </div>
            </div>
            {index < steps.length - 1 && (
              <div
                className={`h-[10px] ${
                  step >= index + 1 || (step === 4 && index === 2)
                    ? "bg_gradient"
                    : "bg-[#24212F]"
                }`}
                style={{ width: "235px", border: "1px solid #302C3F" }}
              ></div>
            )}
            <div className="absolute top-14  left-[0rem]">
              <span className="text-sm text-white">{s.label}</span>
            </div>
          </div>
        ))}
      </div>

     
    </div>
  );
};

export default GenerationBar;
