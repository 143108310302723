import React, { useEffect, useState } from "react";
import logo from "../assets/istaLogo.svg";
import activeDashboard from "../assets/dashboard.png";
import profile from "../assets/profile.png";
import generation from "../assets/generation.png";
import setting from "../assets/setting.png";
import support from "../assets/support.png";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [active, setActive] = useState("dash");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setActive("dash");
    } else if (location.pathname === "/profile") {
      setActive("profile");
    } else if (location.pathname === "/generation") {
      setActive("generation");
    } else if (location.pathname === "/settings") {
      setActive("setting");
    }
  }, [location]);

  return (
    <div className="bg-[#24212F] w-1/4  flex item-center  flex-col ">
      <div className="my-5 ps-9">
        <img src={logo} alt="Logo" />
      </div>
      <div className="h-[2px]  bg-[#302C3F] mb-4"></div>
      <div className="flex flex-col gap-3 p-5  mt-4 text-white">
        <Link
          to={"/"}
          className={`sidebar_border ${
            active === "dash" ? "active-border" : "normal-border"
          }`}
        >
          <img src={activeDashboard} alt="" />
          Dashboard
        </Link>
        <Link
          to={"/profile"}
          className={`sidebar_border text-white ${
            active === "profile" ? "active-border" : "normal-border"
          }`}
        >
          <img src={profile} alt="" />
          Profilo Utente
        </Link>{" "}
        <Link
          to={"/generation"}
          className={`sidebar_border ${
            active === "generation" ? "active-border" : "normal-border"
          }`}
        >
          <img src={generation} alt="" />
          Generazione
        </Link>{" "}
        <Link
          to={"/settings"}
          className={`sidebar_border ${
            active === "setting" ? "active-border" : "normal-border"
          }`}
        >
          <img src={setting} alt="" />
          Impostazioni
        </Link>{" "}
        <Link
          to={"https://istaflow.com/supporto"}
          target="_blank"
          className={`sidebar_border ${
            active === "support" ? "active-border" : "normal-border"
          }`}
        >
          <img src={support} alt="" />
          Supporto
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
