import React, { useEffect, useState } from "react";
import john from "../../assets/john.svg";
import horse from "../../assets/horse.svg";
import facebook from "../../assets/Facebook.svg";
import instagram from "../../assets/instagram.svg";
import linkedin from "../../assets/linkedin.svg";
import newsletter from "../../assets/sm-logo.svg";
import axios from "axios";
import { useMemberstack } from "@memberstack/react";
import { toast } from "react-toastify";
import { IoIosGlobe } from "react-icons/io";

const SocialMedia = ({ prompt, img, setStep, title }) => {
  const memberstack = useMemberstack();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [linkedInData, setLinkedIn] = useState(null);
  const [fbData, setFacebook] = useState(null);
  const [user, setUser] = useState(null);
  const [postsDone, setPostsDone] = useState({
    linkedin: false,
    instagram: false,
    facebook: false,
    blog: false,
  });
  const [fbLoading, setFbLoading] = useState(false);
  const [instaLoading, setInstaLoading] = useState(false);
  const [linkedinLoading, setLinkedinLoading] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);
  const [skip, setSkip] = useState(false);

  useEffect(() => {
    if (
      (postsDone.linkedin ||
        postsDone.instagram ||
        postsDone.facebook ||
        postsDone.blog) &&
      !fbLoading &&
      !instaLoading &&
      !linkedinLoading &&
      !blogLoading
    ) {
      setSkip(true);
    }
    if (
      postsDone.linkedin &&
      postsDone.instagram &&
      postsDone.facebook &&
      postsDone.blog
    ) {
      updateMember();
    }
  }, [postsDone]);

  const updateMember = () => {
    memberstack
      .updateMember({
        customFields: {
          generations: parseInt(user?.customFields?.generations || 0) + 1,
          "remaining-generations":
            parseInt(user?.customFields?.["remaining-generations"] || 0) - 1,
          "articles-generated":
            parseInt(user?.customFields?.["articles-generated"] || 0) + 1,
          "posts-generated":
            parseInt(user?.customFields?.["posts-generated"] || 0) + 1,
          "photos-generated":
            parseInt(user?.customFields?.["photos-generated"] || 0) + 1,
        },
      })
      .then((res) => {
        localStorage.clear();
        setStep(3);
      });
  };

  useEffect(() => {
    memberstack.getCurrentMember().then(({ data: member }) => {
      setUser(member);
      if (
        member?.customFields?.["linkedin-token"] &&
        member?.customFields?.["linkedin-token"] !== ""
      ) {
        setLinkedIn(JSON.parse(member?.customFields?.["linkedin-token"]));
      }

      if (
        member?.customFields?.["fb-token"] &&
        member?.customFields?.["fb-token"] !== ""
      ) {
        setFacebook(JSON.parse(member?.customFields?.["fb-token"]));
      }
    });
  }, [memberstack]);

  useEffect(() => {
    // localStorage.removeItem("socials");
    const socialData = localStorage.getItem("socials");
    if (socialData) {
      setData(JSON.parse(socialData));
      setLoading(false);
    } else {
      axios
        .post("https://hook.eu2.make.com/1wb8wdiuj5yawrbwhf7f054ex57h5tvm", {
          type: "socials",
          prompt,
        })
        .then((res) => {
          if (res.data != "Accepted") {
            setData(res.data);
            localStorage.setItem("socials", JSON.stringify(res.data));

            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, []);

  const postFacebook = () => {
    setFbLoading(true);
    axios
      .post("https://hook.eu2.make.com/5wmirnk8wm3g7v9pp32t5629ogadu3xy", {
        type: "fb",
        image: img,
        text: data.Facebook,
        id: user.id,
      })
      .then((res) => {
        if (res.status === 200 && res.data !== "Accepted") {
          setPostsDone((prev) => {
            return { ...prev, facebook: true };
          });
          toast.success("Posted to facebook successfully");
        } else {
          toast.error(
            "Unable to post to facebook, please try again or connect your facebook account again."
          );
        }
      })
      .catch((err) => {
        toast.error(
          "Unable to post to facebook, please try again or connect your facebook account again."
        );
      })
      .finally(() => setFbLoading(false));
  };
  const postLinkedin = () => {
    setLinkedinLoading(true);
    axios
      .post("https://hook.eu2.make.com/5wmirnk8wm3g7v9pp32t5629ogadu3xy", {
        type: "linkedin",
        image: img,
        text: data.LinkedIn,
        id: user.id,
      })
      .then((res) => {
        if (res.status === 201 && res.data != "Accepted") {
          setPostsDone((prev) => {
            return { ...prev, linkedin: true };
          });
          toast.success("Posted to linkedin successfully");
        } else {
          toast.error(
            "Unable to post to linkedin, please try again or connect your linkedin account again."
          );
        }
      })
      .catch((err) => {
        toast.error(
          "Unable to post to linkedin, please try again or connect your linkedin account again."
        );
      })
      .finally(() => setLinkedinLoading(false));
  };
  const postInstagram = () => {
    setInstaLoading(true);
    axios
      .post("https://hook.eu2.make.com/5wmirnk8wm3g7v9pp32t5629ogadu3xy", {
        type: "insta",
        image: img,
        text: data.Instagram,
        id: user.id,
      })
      .then((res) => {
        if (res.status === 200 && res.data !== "Accepted") {
          setPostsDone((prev) => {
            return { ...prev, instagram: true };
          });
          toast.success("Posted to instagram successfully");
        } else {
          toast.error(
            "Unable to post to instagram, please try again or connect your facebook account again with linked instagram business account."
          );
        }
      })
      .catch((err) => {
        toast.error(
          "Unable to post to instagram, please try again or connect your facebook account again with linked instagram business account"
        );
      })
      .finally(() => setInstaLoading(false));
  };

  const postBlog = () => {
    setBlogLoading(true);
    axios
      .post("https://hook.eu2.make.com/hglftto6y3y4kof85tjwdh34qyts8s8o", {
        title,
        body: prompt,
        image: img,
      })
      .then((res) => {
        if (res.status === 200 && res.data !== "Accepted") {
          setPostsDone((prev) => {
            return { ...prev, blog: true };
          });
          toast.success("Posted to website blog successfully");
        } else {
          toast.error("Unable to post to website blog, please try again.");
        }
      })
      .catch((err) => {
        toast.error("Unable to post to website blog, please try again.");
      })
      .finally(() => setBlogLoading(false));
  };

  const postToAll = () => {
    if (!postsDone.facebook) {
      postFacebook();
    }
    if (!postsDone.linkedin) {
      postLinkedin();
    }
    if (!postsDone.instagram) {
      postInstagram();
    }
    if (!postsDone.blog) {
      postBlog();
    }
  };

  return (
    <div>
      <div className="flex justify-end gap-2 mb-4">
        {skip && (
          <button
            onClick={updateMember}
            className="gradient_border bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2"
          >
            Skip
          </button>
        )}
        <button
          onClick={postToAll}
          disabled={fbLoading || instaLoading || linkedinLoading || loading}
          className="gradient_border  bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2"
        >
          {loading || fbLoading || instaLoading || linkedinLoading
            ? "Posting..."
            : "Post All"}
        </button>
      </div>
      <div className="flex justify-center gap-4">
        {loading && <p className="text-lg font-bold">Generating Posts...</p>}
        {!loading && (
          <>
            <div className="bg-[#24212F] rounded-[10px] p-4 w-1/4 flex flex-col justify-between">
              <div>
                <div className="flex items-center gap-2">
                  <img
                    src={
                      user?.profileImage ||
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                    }
                    alt=""
                    className="w-[40px] h-[40px] rounded-full object-cover"
                  />
                  <span className="text-[12px] font-medium">
                    {user?.customFields?.["first-name"]}{" "}
                    {user?.customFields?.["last-name"]}
                  </span>
                </div>
                <img
                  className="mt-2 w-full object-cover w-full rounded-sm"
                  src={img}
                  alt=""
                />
                {/* <p className="my-2 font-medium ">{item.title}</p> */}
                <p className="text-[#B7B4C7] text-[12px] font-light my-2">
                  {title}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-2 mt-5">
                  <IoIosGlobe size={30} />
                  <span className="fs-[18px]">Website Blog</span>
                </div>
                <button
                  onClick={postBlog}
                  disabled={blogLoading || postsDone.blog}
                  className="gradient_border w-full mt-2 bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2"
                >
                  {blogLoading
                    ? "Posting..."
                    : postsDone.blog
                    ? "Posted"
                    : "Post"}
                </button>
              </div>
            </div>
            {fbData && (
              <div className="bg-[#24212F] rounded-[10px] p-4 w-1/4 flex flex-col justify-between">
                <div>
                  <div className="flex items-center gap-2">
                    <img
                      src={
                        user?.profileImage ||
                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                      }
                      alt=""
                      className="w-[40px] h-[40px] rounded-full object-cover"
                    />
                    <span className="text-[12px] font-medium">
                      {user?.customFields?.["first-name"]}{" "}
                      {user?.customFields?.["last-name"]}
                    </span>
                  </div>
                  <img
                    className="mt-2 w-full object-cover w-full rounded-sm"
                    src={img}
                    alt=""
                  />
                  {/* <p className="my-2 font-medium ">{item.title}</p> */}
                  <p className="text-[#B7B4C7] text-[12px] font-light my-2">
                    {data?.Facebook}
                  </p>
                </div>
                <div>
                  <div className="flex items-center gap-2 mt-5">
                    <img src={facebook} alt="" />
                    <span className="fs-[18px]">Facebook</span>
                  </div>
                  <button
                    onClick={postFacebook}
                    disabled={fbLoading || postsDone.facebook}
                    className="gradient_border w-full mt-2 bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2"
                  >
                    {fbLoading
                      ? "Posting..."
                      : postsDone.facebook
                      ? "Posted"
                      : "Post"}
                  </button>
                </div>
              </div>
            )}
            {fbData && (
              <div className="bg-[#24212F] rounded-[10px] p-4 w-1/4 flex flex-col justify-between">
                <div>
                  <div className="flex items-center gap-2">
                    <img
                      src={
                        user?.profileImage ||
                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                      }
                      alt=""
                      className="w-[40px] h-[40px] rounded-full object-cover"
                    />
                    <span className="text-[12px] font-medium">
                      {user?.customFields?.["first-name"]}{" "}
                      {user?.customFields?.["last-name"]}
                    </span>
                  </div>
                  <img
                    className="mt-2 w-full object-cover w-full rounded-sm"
                    src={img}
                    alt=""
                  />
                  {/* <p className="my-2 font-medium ">{item.title}</p> */}
                  <p className="text-[#B7B4C7] text-[12px] font-light my-2">
                    {data?.Instagram}
                  </p>
                </div>
                <div>
                  <div className="flex items-center gap-2 mt-5">
                    <img src={instagram} alt="" />
                    <span className="fs-[18px]">Instagram</span>
                  </div>
                  <button
                    onClick={postInstagram}
                    disabled={instaLoading || postsDone.instagram}
                    className="gradient_border w-full mt-2 bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2"
                  >
                    {instaLoading
                      ? "Posting..."
                      : postsDone.instagram
                      ? "Posted"
                      : "Post"}
                  </button>
                </div>
              </div>
            )}
            {linkedInData && (
              <div className="bg-[#24212F] rounded-[10px] p-4 w-1/4 flex flex-col justify-between">
                <div>
                  <div className="flex items-center gap-2">
                    <img
                      src={
                        user?.profileImage ||
                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                      }
                      alt=""
                      className="w-[40px] h-[40px] rounded-full object-cover"
                    />
                    <span className="text-[12px] font-medium">
                      {user?.customFields?.["first-name"]}{" "}
                      {user?.customFields?.["last-name"]}
                    </span>
                  </div>
                  <img
                    className="mt-2 w-full object-cover w-full rounded-sm"
                    src={img}
                    alt=""
                  />
                  {/* <p className="my-2 font-medium ">{item.title}</p> */}
                  <p className="text-[#B7B4C7] text-[12px] font-light my-2">
                    {data?.LinkedIn}
                  </p>
                </div>
                <div className="w-full">
                  <div className="flex items-center gap-2 mt-5">
                    <img src={linkedin} alt="" />
                    <span className="fs-[18px]">LinkedIn</span>
                  </div>
                  <button
                    onClick={postLinkedin}
                    disabled={linkedinLoading || postsDone.linkedin}
                    className="gradient_border w-full mt-2 bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2"
                  >
                    {linkedinLoading
                      ? "Posting..."
                      : postsDone.linkedin
                      ? "Posted"
                      : "Post"}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SocialMedia;
