import React, { useEffect, useState } from "react";
import search from "../assets/search.svg";
import notification from "../assets/notification.svg";
import user from "../assets/user.svg";
import { IoLogOutOutline } from "react-icons/io5";
import { useAuth, useMemberstack } from "@memberstack/react";

const Navbar = () => {
  const memberstack = useMemberstack();
  const { signOut } = useAuth();
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState(null);
  const [notifications, setNotifications] = useState(false);

  useEffect(() => {
    memberstack.getCurrentMember().then(({ data: member }) => {
      setProfile(member.profileImage);
    });
  }, [memberstack]);

  const handleSignout = () => {
    signOut();
    window.location.href = "https://istaflow.com/logout";
  };

  return (
    <div className="bg-transparent p-4  flex items-center gap-8">
      <div className="flex items-center gap-4 py-1 ps-4 pe-1 bg-[#13111A] rounded-full searchbar_shadow">
        <input
          type="text"
          placeholder="Ricerca"
          className="w-full  py-2  bg-[transparent]  text-white placeholder-[#5E5678] focus:#5E5678 focus:outline-none"
        />
        <div className="bg-[#302C3F] h-[40px] w-[50px] rounded-full flex justify-center items-center cursor-pointer">
          <img src={search} alt="Search" />
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div
          className="bg-[#1D1A27] h-[40px] w-[40px] rounded-full flex justify-center items-center cursor-pointer relative"
          onClick={() => setNotifications(!notifications)}
        >
          <img src={notification} alt="" />
          {notifications && (
            <div className="absolute top-0 right-32 translate-x-1/2 translate-y-1/2 bg-[#24212F] rounded-lg w-60 searchbar_shadow p-3 z-[1000]">
              <div className="">
                <h2 className="text-bold text-sm">
                  L'abbonamento si rinnova tra 3 giorni
                </h2>
                <p className="text-xs my-1">
                  Questo è un promemoria per ricordarti che tra 3 giorni si
                  rinnova il tuo abbonamento
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="relative">
          <img
            src={
              profile ||
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
            }
            onClick={() => setOpen(!open)}
            alt="Profile"
            className="cursor-pointer w-10 h-10 rounded-full object-cover"
          />

          {open && (
            <div className="postion-absolute bg-[#24212F] bg searchbar_shadow absolute top-12 right-1 py-2 px-3">
              <p
                className="flex items-center gap-2 cursor-pointer"
                onClick={handleSignout}
              >
                <IoLogOutOutline size={20} /> Disconnettersi
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
