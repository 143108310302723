import React from "react";
import ReactDOM from "react-dom/client";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  MemberstackProtected,
  MemberstackProvider,
  SignInModal,
} from "@memberstack/react";
import Loader from "./Components/Loader";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const config = {
  // publicKey: "pk_e5884b618eae1f08fafd",
  publicKey: "pk_sb_88b0e6f59314164b22ed",
  useCookies: true,
  setCookieOnRootDomain: true,
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    toast.error(error);
    toast.error(error?.response?.data?.error);
    // return error;
    // return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MemberstackProvider config={config}>
        <MemberstackProtected
          fallback={<Loader />}
          // allow={{
          //   plans: [
          //     "prc_basic-xk2o0qi7",
          //     "prc_pro-mensile-gb2305k9",
          //     "prc_ultimate-mensile-833s0l98",
          //   ],
          // }}
          onUnauthorized={() =>
            (window.location.href = "https://istaflow.com/logout")
          }
          // onUnauthorized={<SignInModal />}
        >
          <ToastContainer />
          <App />
        </MemberstackProtected>
      </MemberstackProvider>
    </BrowserRouter>
  </React.StrictMode>
);
