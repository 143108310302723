import React, { useEffect, useRef, useState } from "react";
import edit from "../assets/edit.svg";
import { useMemberstack } from "@memberstack/react";
import ProfileModal from "../Components/ProfileModal";
import { FaCamera } from "react-icons/fa";

const CustomerProfile = () => {
  const imgRef = useRef();
  const [modal, setModal] = useState(false);
  const memberstack = useMemberstack();
  const [user, setUser] = useState({});
  useEffect(() => {
    fetchProfile();
  }, [memberstack, modal]);

  const fetchProfile = () => {
    memberstack.getCurrentMember().then(({ data: member }) => {
      setUser(member);
    });
  };

  const handleProfile = (e) => {
    memberstack
      .updateMemberProfileImage({ profileImage: e.target.files[0] })
      .then(() => {
        fetchProfile();
      });
  };

  return (
    <div className="white_border4 rounded-[10px] p-4 bg-[#1D1A27] bg-opacity-50">
      <ProfileModal modal={modal} setModal={setModal} />
      <div className="flex justify-between items-center">
        <span className="font-semibold text-[20px]">Profilo</span>
        <div className="flex gap-4">
          <button
            onClick={() => setModal(true)}
            className="bg-[#302C3F] px-6 py-2 rounded-[30px] text-[14px] flex items-center gap-2"
          >
            <img src={edit} alt="" />
            Modifica
          </button>
        </div>
      </div>

      <div className="mt-4">
        <div className="flex gap-4">
          <div
            className="bg-[#24212F] rounded-[10px] p-4 flex flex-col gap-2 items-center"
            style={{ border: "1px solid #302C3F" }}
          >
            <div
              className="relative cursor-pointer"
              onClick={() => imgRef.current.click()}
            >
              <img
                className="rounded-full w-28 h-28 object-cover"
                src={
                  user?.profileImage ||
                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                }
                alt=""
              />
              <div className="absolute right-0 bottom-1 bg-black rounded-full p-2">
                <FaCamera size={15} />
              </div>
            </div>
            <input
              ref={imgRef}
              className="hidden"
              type="file"
              accept="image/*"
              onChange={handleProfile}
            />
            <p className="font-semibold text-[20px]">{`${user?.customFields?.["first-name"]} ${user?.customFields?.["last-name"]}`}</p>
            <p className="text-[#B7B4C7] font-normal">{user?.auth?.email}</p>
          </div>
          <div className="bg-[#24212F] rounded-[10px] p-4 w-full">
            <p className="font-semibold text-[16px]">Informazioni Utente</p>
            <div className="flex items-center  mt-4">
              <p className="text-[#B7B4C7] font-normal w-1/3">Numero Tel.</p>
              <p className="font-medium">{user?.customFields?.phone}</p>
            </div>
            <div className="flex items-center  mt-4">
              <p className="text-[#B7B4C7] font-normal w-1/3">
                Data di Nascita:
              </p>
              <p className="font-medium">{user?.customFields?.dob}</p>
            </div>
            <div className="flex items-center  mt-4">
              <p className="text-[#B7B4C7] font-normal w-1/3">
                P. IVA (Opzionale):
              </p>
              <p className="font-medium">{user?.customFields?.vat}</p>
            </div>
            <div className="flex items-center  mt-4">
              <p className="text-[#B7B4C7] font-normal w-1/3">
                Generazioni rimanenti:
              </p>
              <p className="font-medium">
                {user?.customFields?.["remaining-generations"] || 0}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
