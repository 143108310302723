import React from "react";
// import ProgressBar from "@ramonak/react-progress-bar";

const TaskBar = ({ ongoing, onHold, finished }) => {
  const total = ongoing + onHold + finished;

  const ongoingPercent = (ongoing / total) * 100;
  const onHoldPercent = (onHold / total) * 100;
  const finishedPercent = (finished / total) * 100;

  const gradient = `linear-gradient(to right, #0ECC68 ${finishedPercent}%, #0D9DFD ${finishedPercent}% ${
    finishedPercent + ongoingPercent
  }%, #FFAD31 ${finishedPercent + ongoingPercent}% ${
    finishedPercent + ongoingPercent + onHoldPercent
  }%)`;

  return (
    <div className="w-full bg-gray-300 rounded-lg h-[4px] overflow-hidden mt-2">
      <div
        className="h-full"
        style={{
          background: gradient,
          width: "100%",
        }}
      ></div>
    </div>
    // <ProgressBar completed={100} animateOnRender={true} bgColor="blue" height="10px" />
  );
};

export default TaskBar;
