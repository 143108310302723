// src/components/DateCalendar.js

import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const DateCalendar = () => {
  const [date, setDate] = useState(new Date());

  const handlePrevMonth = () => {
    setDate(new Date(date.setMonth(date.getMonth() - 1)));
  };

  const handleNextMonth = () => {
    setDate(new Date(date.setMonth(date.getMonth() + 1)));
  };

  return (
    <div className="max-w-md mx-auto ">
      <div className="flex justify-between items-center mb-2 ">
        <span className="text-[#FFFFFF] font-[Inter] text-[20px] font-semibold">
          {format(date, "MMMM yyyy", { locale: it })}
        </span>
        <div className="flex ">
          <button
            onClick={handlePrevMonth}
            className="bg-[#13111A] w-8 h-8 rounded-full flex items-center justify-center mr-[10px]"
          >
            <FaChevronLeft size={12} className="text-[#8D8E94]" />
          </button>
          <button
            className="bg-[#13111A] w-8 h-8 rounded-full flex items-center justify-center"
            onClick={handleNextMonth}
          >
            <FaChevronRight size={12} className="text-[#8D8E94]" />
          </button>
        </div>
      </div>
      <Calendar
        value={date}
        onChange={setDate}
        className="custom-calendar"
        locale="it"
      />
    </div>
  );
};

export default DateCalendar;
