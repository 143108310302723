import React, { useState } from "react";
import send from "../../assets/send.svg";

const SearchBar = ({ prompt, setPrompt, sendPrompt, disabled }) => {
  return (
    <div className="flex items-center justify-center mt-[7rem] gap-5 w-full">
      <form
        onSubmit={sendPrompt}
        style={{ border: "1px solid #555066" }}
        className="w-[80%] rounded-full flex items-center justify-between ps-5 pe-1 bg-[#24212F] w-full"
      >
        <input
          className="text-[#B7B4C7] w-full py-3 bg-transparent focus:outline-none block"
          type="text"
          placeholder="Inserisci l’argomento..."
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <button
          type="submit"
          disabled={disabled}
          style={{ border: "1px solid #302C3F" }}
          className="w-10 h-10 rounded-full flex items-center justify-center bg-[#24212F] cursor-pointer gradient_border "
        >
          <img src={send} alt="" />
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
