import { useMemberstack } from "@memberstack/react";
import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LinkedinCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const memberstack = useMemberstack();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const error = params.get("error");
    if (error) {
      navigate("/settings");
      return;
    }

    if (code) {
      // Send the code to your MAKE webhook
      axios
        .post("https://hook.eu2.make.com/abl6hj51cyxrxfnffccjo1zzm6ub24w4", {
          type: "linkedin",
          code,
        })
        .then((res) => {
          // Handle the response data, e.g., update Memberstack user profile
          console.log("Success:", res.data);
          if (typeof res.data === "string") {
            // toast.error("Please try again");
            navigate("/settings");
          } else {
            memberstack
              .updateMember({
                customFields: {
                  "linkedin-token": JSON.stringify({
                    ...res.data,
                    expires_at:
                      new Date().getTime() + res.data?.expires_in * 1000,
                  }),
                },
              })
              .then(() => {
                // navigate("/settings");
              });
          }
        })
        .catch((error) => {
          toast.error("Please try again");
          //   navigate("/settings");
        });
    }
  }, []);
  return (
    <div className="bg-[#13111A] fixed top-0 left-0 h-screen w-screen flex items-center justify-center z-50">
      <h1 className="text-3xl font-bold">Please wait while redirecting...</h1>
    </div>
  );
};

export default LinkedinCallback;
