import { useEffect, useState } from "react";
import project from "../assets/project.svg";
import task from "../assets/task.svg";
import bugs from "../assets/bugs.svg";
import users from "../assets/profile-2user.svg";
import TaskBar from "../Components/TaskBar";
import DateCalendar from "../Components/DateCalendar";
import { Link } from "react-router-dom";
import { useMemberstack } from "@memberstack/react";
import { useNavigate } from "react-router-dom";
import { WebflowClient } from "webflow-api";
import axios from "axios";

const Dashboard = () => {
  const webflow = new WebflowClient({
    accessToken:
      "3908480d3febfae0cf2ad2979721a253c7dedbd6a09dee2cba61ebbca3f10844",
  });
  const navigate = useNavigate();
  const memberstack = useMemberstack();
  const [user, setUser] = useState(null);

  useEffect(() => {
    navigate("/generation");
  }, []);

  useEffect(() => {
    memberstack.getCurrentMember().then(({ data: member }) => {
      setUser(member);
    });
  }, [memberstack]);

  return (
    <div>
      <div className="flex justify-between items-center gap-5 ">
        <div className="white_border4 flex items-center gap-3 bg-[#1D1A27] bg-opacity-50 rounded-[10px] py-4 ps-4 w-1/2">
          <div
            className="flex rounded-full w-14 h-14 items-center justify-center"
            style={{ backgroundColor: "rgba(14, 204, 104, 0.1)" }}
          >
            <img src={project} alt="" />
          </div>
          <div>
            <p className="text-2xl font-semibold mb-1">
              {user?.customFields?.["articles-generated"] || 0}
            </p>
            <span className="text-[#B7B4C7] font-normal">
              Articoli Generati
            </span>
          </div>
        </div>{" "}
        <div className="white_border4 flex items-center gap-3 bg-[#1D1A27] bg-opacity-50 rounded-[10px] py-4 ps-4 w-1/2">
          <div
            className="flex rounded-full w-14 h-14 items-center justify-center"
            style={{ backgroundColor: "rgba(14, 204, 104, 0.1)" }}
          >
            <img src={task} alt="" />
          </div>
          <div>
            <p className="text-2xl font-semibold mb-1">
              {user?.customFields?.["posts-generated"] || 0}
            </p>
            <span className="text-[#B7B4C7] font-normal">Post Generati</span>
          </div>
        </div>
        <div className="white_border4 flex items-center gap-3 bg-[#1D1A27] bg-opacity-50 rounded-[10px] py-4 ps-4 w-1/2">
          <div
            className="flex rounded-full w-14 h-14 items-center justify-center"
            style={{ backgroundColor: "rgba(14, 204, 104, 0.1)" }}
          >
            <img src={bugs} alt="" />
          </div>
          <div>
            <p className="text-2xl font-semibold mb-1">
              {user?.customFields?.["photos-generated"] || 0}
            </p>
            <span className="text-[#B7B4C7] font-normal">Foto Generate</span>
          </div>
        </div>{" "}
        <div className="white_border4 flex items-center gap-3 bg-[#1D1A27] bg-opacity-50 rounded-[10px] py-4 ps-4 w-1/2">
          <div
            className="flex rounded-full w-14 h-14 items-center justify-center"
            style={{ backgroundColor: "rgba(14, 204, 104, 0.1)" }}
          >
            <img src={users} alt="" />
          </div>
          <div>
            <p className="text-2xl font-semibold mb-1">36</p>
            <span className="text-[#B7B4C7] font-normal">
              Clienti Raggiunti
            </span>
          </div>
        </div>
      </div>
      <div className="flex gap-5 items-start mt-5">
        <div className="white_border4 bg-[#1D1A27] rounded-[10px] w-full h-[23rem]  font-semibold text-[16px] p-4">
          Tutorial Piattaforma
        </div>
        <div className="w-1/2">
          <div className="rounded-[10px] white_border4  bg-[#1D1A27]   p-4">
            <div className="flex items-center justify-between mb-3">
              <span className="font-semibold text-[16px] ">Panoramica</span>
              <select
                className="bg-[transparent] text-[#B7B4C7] py-2 px-3 rounded-full focus:outline-none"
                style={{ border: "1px solid #302C3F" }}
                name=""
                id=""
              >
                <option value="Today">Oggi</option>
              </select>
            </div>
            <span className="font-bold text-2xl ">62</span>
            <p className="text-[#B7B4C7] mt-2">Progetti Questo Mese</p>
            <TaskBar ongoing={12} onHold={14} finished={123} />

            <div className="mt-3">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <div className="bg-[#0D9DFD] rounded w-3 h-3"></div>
                  <span className="text-[#B7B4C7]">Generazioni</span>
                </div>
                <div
                  className="flex items-center justify-center py-1 px-2 rounded"
                  style={{ backgroundColor: "rgba(13, 157, 253, 0.1)" }}
                >
                  <span className="text-[#0D9DFD] text-[12px]"> +15 </span>
                </div>
              </div>
              <div className="flex justify-between items-center mt-3">
                <div className="flex items-center gap-2">
                  <div className="bg-[#FFC107] rounded w-3 h-3"></div>
                  <span className="text-[#B7B4C7]">Bozze</span>
                </div>
                <div
                  className="flex items-center justify-center py-1 px-2 rounded"
                  style={{ backgroundColor: "rgba(255, 173, 49, 0.1)" }}
                >
                  <span className="text-[#FFAD31] text-[12px]"> +15 </span>
                </div>
              </div>
              <div className="flex justify-between items-center mt-3">
                <div className="flex items-center gap-2">
                  <div className="bg-[#28C76F] rounded w-3 h-3"></div>
                  <span className="text-[#B7B4C7]">Inviati</span>
                </div>
                <div
                  className="flex items-center justify-center py-1 px-2 rounded"
                  style={{ backgroundColor: "rgba(14, 204, 104, 0.1)" }}
                >
                  <span className="text-[#0ECC68] text-[12px]"> +15 </span>
                </div>
              </div>
            </div>
            <div className="mt-4 ">
              <Link
                to={"/generation"}
                className="gradient_border text-center rounded-full w-full py-3 block"
              >
                Crea Nuovo Progetto
              </Link>
            </div>
          </div>
          <div className="rounded-[10px] white_border4  bg-[#1D1A27] p-4 mt-5">
            <DateCalendar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
