import Modal from "react-responsive-modal";
import { IoClose } from "react-icons/io5";
import PhoneInput from "react-phone-number-input";
import { useEffect, useState } from "react";
import { useMemberstack } from "@memberstack/react";

const ProfileModal = ({ modal, setModal }) => {
  const memberstack = useMemberstack();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    "first-name": "",
    "last-name": "",
    phone: "",
    vat: "",
    dob: "",
  });

  useEffect(() => {
    memberstack.getCurrentMember().then(({ data: member }) => {
      setData({
        ...data,
        ...member.customFields,
      });
    });
  }, [memberstack]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    memberstack
      .updateMember({
        customFields: data,
      })
      .then(() => {
        setModal(false);
      });
  };

  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      center
      classNames={{
        modal: "rounded-[10px] p-4 bg-[#1D1A27] text-white w-6/12",
        closeIcon: "text-white",
      }}
      closeIcon={<IoClose size={20} />}
    >
      <div>
        <h1 className="text-[20px]">Modifica Profilo</h1>
      </div>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="firstName">Name</label>
          <input
            type="text"
            name="first-name"
            id="firstName"
            placeholder="eg: John"
            className="profile-input"
            value={data["first-name"]}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <label htmlFor="lastName">Cognome</label>
          <input
            type="text"
            name="last-name"
            id="lastName"
            placeholder="eg: Doe"
            className="profile-input"
            value={data["last-name"]}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <label>Numero Tel.</label>
          <PhoneInput
            placeholder="eg: 123456789"
            className="profile-input"
            onChange={(e) => setData({ ...data, phone: e })}
            value={data.phone}
          />
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <label htmlFor="dob">Data di Nascita</label>
          <input
            type="date"
            name="dob"
            id="dob"
            className="profile-input"
            value={data.dob}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <label htmlFor="vat">P. IVA (Opzionale)</label>
          <input
            type="text"
            name="vat"
            id="vat"
            placeholder="eg: 30"
            className="profile-input"
            value={data.vat}
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          className="w-full text-center !block sidebar_border active-border mt-4"
        >
          {loading ? "Salvataggio..." : "Salva"}
        </button>
      </form>
    </Modal>
  );
};

export default ProfileModal;
