import Modal from "react-responsive-modal";
import icon from "../../assets/danger.png";

const GenerationLimit = ({ modal, setModal }) => {
  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      center
      classNames={{
        modal: "rounded-[10px] px-4 py-5 bg-[#24212F] text-white w-1/3 h-1/3",
        closeIcon: "text-white hidden",
      }}
    >
      <div className="flex items-center justify-center flex-col h-full">
        <div className="modal-icon w-10 h-10 rounded-full flex justify-center items-center bg-[#2D2A37]">
          <img src={icon} alt="" className="w-3/5" />
        </div>
        <h3 className="modal-text mt-3">Limite di generazione raggiunto</h3>
      </div>
    </Modal>
  );
};

export default GenerationLimit;
