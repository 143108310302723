import Navbar from "./Components/Navbar";
import Sidebar from "./Components/Sidebar";
import Dashboard from "./Routes/Dashboard";
import CustomerProfile from "./Routes/CustomerProfile";
import Generation from "./Routes/Generation";
import Setting from "./Routes/Setting";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./App.css";
import { useEffect, useState } from "react";
import { useMemberstack, useAuth } from "@memberstack/react";
import FacebookCallback from "./Routes/FacebookCallback";
import axios from "axios";
import { toast } from "react-toastify";
import InstaCallback from "./Routes/InstaCallback";
import LinkedinCallback from "./Routes/LinkedinCallback";

function App() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const memberstack = useMemberstack();
  const [user, setUser] = useState(null);

  useEffect(() => {
    memberstack
      .getCurrentMember()
      .then(({ data: member }) => {
        setUser(member);
      })
      .catch();
  }, [memberstack]);

  useEffect(() => {
    if (
      user &&
      user?.customFields?.["fb-token"] &&
      user?.customFields?.["fb-token"] !== ""
    ) {
      let token = user?.customFields?.["fb-token"];
      token = JSON.parse(token);
      if (new Date().getTime() > token.expires_at) {
        axios
          .post("https://hook.eu2.make.com/abl6hj51cyxrxfnffccjo1zzm6ub24w4", {
            type: "fb-refresh",
            code: token?.access_token,
          })
          .then((res) => {
            // Handle the response data, e.g., update Memberstack user profile
            if (typeof res.data !== "string") {
              memberstack.updateMember({
                customFields: {
                  "fb-token": JSON.stringify({
                    ...res.data,
                    expires_at:
                      new Date().getTime() + res.data?.expires_in * 1000,
                  }),
                },
              });
            }
          });
      }
    }
    if (
      user &&
      user?.customFields?.["insta-token"] &&
      user?.customFields?.["insta-token"] !== ""
    ) {
      let token = user?.customFields?.["insta-token"];
      token = JSON.parse(token);
      if (new Date().getTime() > token.expires_at) {
        axios
          .post("https://hook.eu2.make.com/abl6hj51cyxrxfnffccjo1zzm6ub24w4", {
            type: "insta-refresh",
            code: token?.access_token,
          })
          .then((res) => {
            // Handle the response data, e.g., update Memberstack user profile
            console.log("Success:", res.data);
            if (typeof res.data !== "string") {
              memberstack.updateMember({
                customFields: {
                  "insta-token": JSON.stringify({
                    ...res.data,
                    expires_at:
                      new Date().getTime() + res.data?.expires_in * 1000,
                  }),
                },
              });
            }
          });
      }
    }
  }, [user]);

  const [heading, setHeading] = useState("Dashboard");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setHeading("Dashboard");
    } else if (location.pathname === "/profile") {
      setHeading("Profilo Utente");
    } else if (location.pathname === "/generation") {
      setHeading("Generazione");
    } else if (location.pathname === "/settings") {
      setHeading("Impostazioni");
    }
  }, [location]);

  return (
    <>
      {isLoggedIn && (
        <div className="flex min-h-screen">
          <Sidebar />
          <div className="flex-1 bg-[#13111A] relative overflow-hidden text-white p-5">
            <div
              data-w-id="79d611fc-2ab3-924b-1c09-891dd8dec4a6"
              class="bg-gradient-blur-wrapper home-hero-1 bg-animate"
              // style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(55.188deg) skew(0deg, 0deg); transform-style: preserve-3d; will-change: transform;"
            >
              <div class="bg-gradient-blur-circle-3"></div>
              <div class="bg-gradient-blur-circle-2"></div>
              <div class="bg-gradient-blur-circle-1"></div>
            </div>
            <div
              class="bg-gradient-blur-wrapper home-hero-2 bg-animate"
              // style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(52.1928deg) skew(0deg, 0deg); transform-style: preserve-3d; will-change: transform;"
            >
              <div class="bg-gradient-blur-circle-3"></div>
              <div class="bg-gradient-blur-circle-2 blue"></div>
              <div class="bg-gradient-blur-circle-1 purple"></div>
            </div>
            <div className="flex justify-between items-center">
              <span className="font-semibold text-2xl z-50">{heading}</span>
              {/* <span className="font-semibold text-2xl">Customer Profile</span> */}
              {/* <span className="font-semibold text-2xl">Generation</span> */}
              {/* <span className="font-semibold text-2xl">Setting</span> */}
              <Navbar />
            </div>
            <div className="mt-2 relative">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/profile" element={<CustomerProfile />} />
                <Route path="/generation" element={<Generation />} />
                <Route path="/settings" element={<Setting />} />
                <Route
                  path="/auth/facebook/callback"
                  element={<FacebookCallback />}
                />
                <Route
                  path="/auth/instagram/callback"
                  element={<InstaCallback />}
                />
                <Route
                  path="/auth/linkedin/callback"
                  element={<LinkedinCallback />}
                />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
