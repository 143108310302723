import React, { useEffect, useState } from "react";
import logo from "../../assets/sm-logo.svg";
import SearchBar from "./SearchBar";
import { useMemberstack } from "@memberstack/react";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import GenerationLimit from "../Modals/GenerationLimit";

const Article = ({ setStep, setData }) => {
  const memberstack = useMemberstack();
  const [limitModal, setLimitModal] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [user, setUser] = useState("");
  const [edit, setEdit] = useState(false);
  const [input, setInput] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);

  useEffect(() => {
    memberstack.getCurrentMember().then(({ data: member }) => {
      setUser(member);
    });
  }, [memberstack]);

  useEffect(() => {
    // localStorage.clear();
    let prevArticle = localStorage.getItem("article");
    if (prevArticle) {
      let article = JSON.parse(prevArticle);
      setInput(article.prompt);
      setResponse(article.response);
      setGenerated(true);
    }
  }, []);

  const handleNextStep = () => {
    setData((prev) => ({
      ...prev,
      article: response?.article,
      generatedPrompt: response?.prompt,
      prompt: input,
    }));
    setStep(1);
  };

  const handlePrompt = (e) => {
    e.preventDefault();
    if (
      user?.customFields?.["remaining-generations"] < 1 ||
      user?.customFields?.["remaining-generations"] == ""
    ) {
      setLimitModal(true);
      return;
    }
    setInput(prompt);
    setPrompt("");

    setLoading(true);
    axios
      .post("https://hook.eu2.make.com/1wb8wdiuj5yawrbwhf7f054ex57h5tvm", {
        type: "article",
        prompt,
      })
      .then((res) => {
        setLoading(false);
        if (res.data?.article) {
          setResponse(res.data);
          localStorage.setItem(
            "article",
            JSON.stringify({ prompt, response: res.data })
          );
          setGenerated(true);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <GenerationLimit modal={limitModal} setModal={setLimitModal} />
      {input ? (
        <div className="flex gap-6 mt-5 items-start">
          <div
            style={{ border: "1px solid #302C3F" }}
            className="w-14 h-14 rounded-full flex justify-center items-center bg-[#24212F] overflow-hidden"
          >
            <img
              className="object-cover w-full h-full"
              src={
                user?.profileImage ||
                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
              }
              alt=""
            />
          </div>{" "}
          <p className="font-medium text-[16px] mb-0 w-fit mt-1">{input}</p>
        </div>
      ) : (
        <h2 className="text-center">Enter any topic to generate article</h2>
      )}{" "}
      {response?.article && (
        <div>
          <div className="flex items-start gap-6 mt-5">
            <div
              style={{ border: "1px solid #302C3F" }}
              className="rounded-full w-14 h-14 flex justify-center items-center bg-[#24212F]"
            >
              <img className="w-1/2 h-1/2 object-contain" src={logo} alt="" />
            </div>
            {edit ? (
              <div className=" bg-[#24212F] rounded-[10px] p-4 w-full">
                {/* <textarea
                  className="w-full bg-[#24212F] focus:outline-none"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  ></textarea> */}
                <ReactQuill
                  value={response?.article}
                  onChange={(value) =>
                    setResponse({ ...response, article: value })
                  }
                  className="w-full bg-[#24212F] focus:outline-none"
                />
                <div className="flex gap-4 mt-4 justify-end items-center">
                  <button
                    onClick={() => setEdit(!edit)}
                    className=" mt-4 bg-[#D9D9D9] text-[#5E5678] font-medium rounded-full py-2 px-6"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleNextStep}
                    className="gradient_border mt-4 bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="mb-0 w-fit reset"
                dangerouslySetInnerHTML={{ __html: response?.article }}
              ></div>
            )}
          </div>
          {!edit && (
            <div className="flex gap-4 mt-4 justify-end items-center">
              <button
                onClick={() => setEdit(!edit)}
                className=" mt-4 bg-[#D9D9D9] text-[#5E5678] font-medium rounded-full py-2 px-6"
              >
                Modificare
              </button>
              <button
                onClick={handleNextStep}
                className="gradient_border mt-4 bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2"
              >
                Conferma
              </button>
            </div>
          )}
        </div>
      )}
      {/* })} */}
      {loading && (
        <div className=" flex items-center gap-6 mt-5">
          <div
            style={{ border: "1px solid #302C3F" }}
            className="rounded-full w-14 h-14 flex justify-center items-center bg-[#24212F]"
          >
            <img className="w-1/2 h-1/2 object-contain" src={logo} alt="" />
          </div>
          <p className="font-medium text-[16px] mb-0">
            <ThreeDots
              visible={loading}
              height="40"
              width="50"
              color="#fafafa"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </p>
        </div>
      )}
      <SearchBar
        prompt={prompt}
        setPrompt={setPrompt}
        sendPrompt={handlePrompt}
        disabled={loading || generated}
      />
    </div>
  );
};

export default Article;
