import React, { useState } from "react";
import GenerationBar from "../Components/generation/GenerationBar";
import gallery from "../assets/gallery.svg";
import article from "../assets/article.svg";
import post from "../assets/post.svg";
import newsletter from "../assets/newsletter.svg";
import Article from "../Components/generation/Article";
import SocialMedia from "../Components/generation/SocialMedia";
import ImgGeneration from "../Components/generation/ImgGeneration";
import SearchBar from "../Components/generation/SearchBar";
import SuccessModal from "../Components/generation/SuccessModal";

const steps = [
  { icon: article, label: "Articolo" },
  { icon: gallery, label: "Immagine" },
  { icon: post, label: "Post Social" },
  { icon: newsletter, label: "Newsletter" },
];

const Generation = () => {
  const [step, setStep] = useState(0);
  const [isModalOpen, setModalOpen] = useState(true);
  const [data, setData] = useState({
    article: "",
    generatedPrompt: "",
    prompt: "",
    image: "",
  });

  const handleConfirmAllClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="white_border4 rounded-[10px] p-4 bg-[#1D1A27] bg-opacity-50">
      {/* socialMedia  heading and button here  */}

      {/* <div className="flex justify-between items-center">
        <p className="font-semibold text-[20px]">Social Media Preview</p>
        <div className="flex gap-4 items-center">
          <button className="bg-[#D9D9D9] text-[#5E5678] font-medium rounded-full py-2 px-6">
            Edit
          </button>
          <button
            onClick={handleConfirmAllClick}
            className="gradient_border bg-[#13111A] text-[#FFFFFF] font-medium rounded-full px-6 py-2"
          >
            Confirm All
          </button>
        </div>
      </div> */}

      {/* //// */}

      <p className="font-semibold text-[20px]">Generazione</p>
      {/* <p className="font-semibold text-[20px]">Generation Steps</p> */}

      <GenerationBar steps={steps} step={step} />
      <div className="mt-14">
        {step === 0 && <Article setData={setData} setStep={setStep} />}
        {step === 1 && (
          <ImgGeneration
            setStep={setStep}
            prompt={data.generatedPrompt}
            setData={setData}
          />
        )}
        {step === 2 && (
          <SocialMedia
            prompt={data?.article}
            setStep={setStep}
            img={data.image}
            title={data.prompt}
          />
        )}
        {step === 3 && <SuccessModal />}
      </div>
    </div>
  );
};

export default Generation;
