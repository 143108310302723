import { useMemberstack } from "@memberstack/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Modal from "react-responsive-modal";

const WebDetailsModal = ({ modal, setModal }) => {
  const memberstack = useMemberstack();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    website: "",
    user: "",
    pass: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // memberstack
    //   .updateMember({
    //     customFields: { web: data },
    //   })
    //   .then(() => {
    //     setModal(false);
    //   });
    const authString = `${data.user}:${data.pass}`;

    const encodedAuthString = btoa(authString);
    axios
      .post("https://hook.eu2.make.com/ailah21kd7y7i0q2y7vvbt4ebc03pxv6", {
        website: data.website,
        token: encodedAuthString,
      })
      .then((res) => {
        console.log("Success: " + res.data);
      })
      .catch((err) => {
        console.log("Error: " + err);
      });
  };

  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      center
      classNames={{
        modal: "rounded-[10px] p-4 bg-[#1D1A27] text-white w-[500px]",
        closeIcon: "text-white",
      }}
      closeIcon={<IoClose size={20} />}
    >
      <div>
        <h1 className="text-[20px]">Add your website credentials</h1>
      </div>
      <p className="text-[#B7B4C7] text-[15px] mt-2">
        Puoi generare la password dell'app WordPress andando su{" "}
        <b>Users {">"} Profile</b> E <b>Application Passwords</b> sezione.
      </p>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="website">URL del sito</label>
          <input
            type="text"
            name="website"
            id="website"
            placeholder="https://example.com"
            className="profile-input"
            value={data.website}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col gap-2 my-3">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            name="user"
            id="username"
            placeholder="Your wordpress username"
            className="profile-input"
            value={data.user}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="pass">App Password</label>
          <input
            type="text"
            name="pass"
            id="pass"
            placeholder="Your generated password"
            className="profile-input"
            value={data.pass}
            onChange={handleChange}
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full text-center !block sidebar_border active-border mt-4"
        >
          {loading ? "Salvataggio..." : "Fatto"}
        </button>
      </form>
    </Modal>
  );
};

export default WebDetailsModal;
