import { Circles, ColorRing } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center bg-slate-800">
      <Circles
        height="100"
        width="100"
        color="#fc7588"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
