import React, { useEffect, useState } from "react";
import facebook from "../assets/metaApi.svg";
import linkedin from "../assets/linkedin.svg";
import { useMemberstack } from "@memberstack/react";
import { IoIosGlobe } from "react-icons/io";
import WebDetailsModal from "../Components/WebDetailsModal";

const Setting = () => {
  const memberstack = useMemberstack();
  const [user, setUser] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [webModal, setWebModal] = useState(false);

  useEffect(() => {
    if (memberstack) {
      memberstack
        .getCurrentMember()
        .then(({ data: member }) => setUser(member));
    }
  }, [memberstack, updated]);

  const handleFb = () => {
    if (
      user?.customFields?.["fb-token"] &&
      user?.customFields?.["fb-token"] !== ""
    ) {
      memberstack
        .updateMember({ customFields: { "fb-token": "" } })
        .then(() => {
          setUpdated(!updated);
        });
    } else {
      const appId = "733145932215186";
      // const redirectUri = "http://localhost:3000/auth/facebook/callback";
      const redirectUri =
        "https://dashboard.istaflow.com/auth/facebook/callback";
      const authUrl = `https://www.facebook.com/v20.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=email,public_profile,pages_manage_posts,publish_video,pages_show_list,ads_management,business_management,instagram_basic,instagram_content_publish,pages_read_engagement`;
      window.location.href = authUrl;
    }
  };

  const linkedInLogin = () => {
    if (
      user?.customFields?.["linkedin-token"] &&
      user?.customFields?.["linkedin-token"] !== ""
    ) {
      memberstack
        .updateMember({ customFields: { "linkedin-token": "" } })
        .then(() => {
          setUpdated(!updated);
        });
    } else {
      // clientId: "789t47qw58c2xl", dreOjr7aLoy7e8Fz
      const linkedInConfig = {
        clientId: "77wq0o85dut7vm",
        // redirectUri: "http://localhost:3000/auth/linkedin/callback",
        redirectUri: "https://dashboard.istaflow.com/auth/linkedin/callback",
        scope: "openid%20profile%20email%20w_member_social",
        state: (Math.random() + 1).toString(36).substring(7),
        responseType: "code",
      };

      const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=${linkedInConfig.responseType}&client_id=${linkedInConfig.clientId}&redirect_uri=${linkedInConfig.redirectUri}&scope=${linkedInConfig.scope}&state=${linkedInConfig.state}`;
      window.location.href = linkedInAuthUrl;
    }
  };

  return (
    <div className="white_border4 rounded-[10px] p-4 bg-[#1D1A27] bg-opacity-50">
      <WebDetailsModal modal={webModal} setModal={setWebModal} />
      <p className="font-semibold text-[20px]">Integrations & APIs</p>
      <div className="flex flex-wrap gap-4 mt-4">
        <div className="p-4 bg-[#24212F] rounded-[10px] w-[20rem] h-[20rem] relative">
          <img className="w-10" src={facebook} alt="" />
          <p className="my-2 font-semibold">Meta API</p>
          <p className="text-[#B7B4C7] text-[12px]">
            Collega IstaFlow con Meta per sfruttare le funzionalità di
            pubblicazione nei profili Facebook e Instagram.
          </p>

          <div className="flex  justify-between w-full mt-8 absolute bottom-4 ">
            <p className="bg-[#24212F] text-[#FFFFFF] font-normal py-2">
              {user?.customFields?.["fb-token"] &&
              user?.customFields?.["fb-token"] !== ""
                ? "Connesso"
                : "Non Connesso"}
            </p>
            <button
              onClick={handleFb}
              className="mr-8 gradient_border bg-[#13111A] text-[#FFFFFF] font-normal rounded-full px-6 py-2"
            >
              {user?.customFields?.["fb-token"] &&
              user?.customFields?.["fb-token"] !== ""
                ? "Disconnetti"
                : "Connetti"}
            </button>
          </div>
        </div>

        <div className="p-4 bg-[#24212F] rounded-[10px] w-[20rem] h-[20rem] relative">
          <img className="w-8" src={linkedin} alt="" />
          <p className="mb-1 font-semibold">LinkedIn API</p>
          <p className="text-[#B7B4C7] text-[12px]">
            Collega IstaFlow con LinkedIn per sfruttare le funzionalità di
            pubblicazione nel profilo LinkedIN.
          </p>

          <div className="flex  justify-between w-full mt-8 absolute bottom-4 ">
            <p className="bg-[#24212F] text-[#FFFFFF] font-normal py-2">
              {user?.customFields?.["linkedin-token"] &&
              user?.customFields?.["linkedin-token"] !== ""
                ? "Connesso"
                : "Non Connesso"}
            </p>
            <button
              onClick={linkedInLogin}
              className="mr-8 gradient_border bg-[#13111A] text-[#FFFFFF] font-normal rounded-full px-6 py-2"
            >
              {user?.customFields?.["linkedin-token"] &&
              user?.customFields?.["linkedin-token"] !== ""
                ? "Disconnetti"
                : "Connetti"}
            </button>
          </div>
        </div>
        <div className="p-4 bg-[#24212F] rounded-[10px] w-[20rem] h-[20rem] relative">
          <IoIosGlobe size={30} />
          <p className="my-2 font-semibold">Sito Web - API</p>
          <p className="text-[#B7B4C7] text-[12px]">
            Collega IstaFlow con il tuo sito Web per sfruttare le funzionalità
            di pubblicazione nel Blog.
          </p>

          <div className="flex  justify-between w-full mt-8 absolute bottom-4 ">
            <p className="bg-[#24212F] text-[#FFFFFF] font-normal py-2">
              {user?.customFields?.["web"] && user?.customFields?.["web"] !== ""
                ? "Connesso"
                : "Non Connesso"}
            </p>
            <button
              onClick={() => setWebModal(true)}
              className="mr-8 gradient_border bg-[#13111A] text-[#FFFFFF] font-normal rounded-full px-6 py-2"
            >
              {user?.customFields?.["web"] && user?.customFields?.["web"] !== ""
                ? "Disconnetti"
                : "Connetti"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
